import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/UsecaesHeader";
import Container from "../../components/chat-channel/Container";
import AppsIntegrations from "../../components/common/usecases/appsIntegrations";
import ArrowRightICon from "../../components/common/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import Cards from "../../components/common/knowledge/Cards";

import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { Slider } from "../../components/common/sliderHomePage";

import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";

export const blogImg = require("../../assets/img/integration/card_1.png");
export const whitepaperImg = require("../../assets/img/key-resources/bc_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

const section_1 = require("../../assets/images/usecases/desktop/usecase_icon_1.png");
const section_2 = require("../../assets/images/usecases/desktop/usecase_icon_2.png");

const section_1_mob = require("../../assets/images/usecases/mobile/usecase_icon_1_mob.png");
const section_2_mob = require("../../assets/images/usecases/mobile/usecase_icon_2_mob.png");

const slackteam = require("../../assets/images/usecases/ai_ticketing/ai_ticketing_last.png");
const aboveFoldImage = require("../../assets/images/usecases/ai_ticketing/ai_ticketing_header.png");
const automation_steps = require("../../assets/images/usecases/ai_ticketing/ticketing_steps.png");
const mob_onboard_steps = require("../../assets/images/usecases/mobile/mob_ticketing_steps.png");

// const aboveFoldImage = require("../../assets/images/usecases/onboarding/video_bg.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "With Workativ and Zendesk, create a whole new level of IT support experience.",
    url:
      "https://blog.workativ.com/using-zendesk-then-you-need-to-take-a-look-at-this/",
    link: "Read Blog",
    alt: "AI Ticketing Automation",
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for a business with the Zendesk and Workativ combination?",
    url:
      "https://blog.workativ.com/not-yet-using-zendesk-with-workativ-assistant-chatbot-youre-missing-out-a-lot/",
    link: "Read Blog",
    alt: "AI Ticketing Blog",
  },
  {
    image: videoImg,
    header: "A step by step guide to Zendesk ’create ticket’ automation. ",
    url: "https://youtu.be/-LpKr0FH5KE",
    link: "Watch Video",
    alt: "AI Ticketing video",
  },
];
export const cardData = [
  {
    href: null,
    header: "Problem",
    content:
      "Many calls reach the helpdesk for low-level issues with employees waiting on tickets to get resolved.",
    backgroundClass: "card_one",
  },
  {
    href: null,
    header: "Solution",
    content:
      "AI-powered chatbot that delivers an automated resolution of repetitive issues and reduces the requests to helpdesk.",
    backgroundClass: "card_two",
  },
  {
    href: null,
    header: "Impact",
    content:
      "A more personalized ticketing process with intelligent AI improves the employee experience and increases productivity.",
    backgroundClass: "card_three",
  },
];

const headerMenu = [
  {
    menu: "Overview",
    href: "#section_1",
    id: "section_1",
  },
  {
    menu: " Why digital AI Ticketing",
    href: "#section_2",
    id: "section_2",
  },
  {
    menu: "AI Ticketing automation steps",
    href: "#section_3",
    id: "section_3",
  },
  {
    menu: "AI Ticketing chatbot on Slack and Teams",
    href: "#section_4",
    id: "section_4",
  },
  {
    menu: "Other popular use cases",
    href: "#section_5",
    id: "section_3",
  },
];

const sectionImg = [
  {
    img: section_1,
    mobImage: section_1_mob,
    alt: "AI Ticketing Automation",
    header: "AI Ticketing",
    paragraph:
      "Ticketing with AI brings a host of benefits to both support teams and employees. Support teams get more time in the day to focus on more pressing issues instead of being bogged down by low-level issues. Through conversational AI, the intelligent system can understand the user’s intent to resolve their issue. AI self-service ticketing combined with automation enables employees to be more productive instead of waiting on the helpdesk to resolve their issues and eliminate follow-up on ticket status.",
  },
  {
    img: section_2,
    mobImage: section_2_mob,
    alt: "Ticketing Automation",
    header: "Ticketing Automation",
    paragraph:
      "On Workativ’s platform, creating ticketing automation workflows is a breeze. It is enhanced with AI to enable users to resolve support issues independently or allow an AI-powered chatbot to create a ticket for the employee if the problem doesn’t get resolved instantly. Workativ integrates with popular helpdesk and ticketing tools. You can use the desired workflows for a variety of use cases and employees can create, update, and retrieve ticket details easily.",
  },
];

export default function HomePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState("Overview");

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("Overview");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="AI Ticketing for Helpdesk Support - Workplace support automation software - Workativ"
        description="Deploy AI Ticketing Automation in Hours – Streamline ticketing with AI-powered automation and helpdesk integration in Slack & Teams. Start automating now!"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={slackteam}
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <section className={header}>
            <div className="container-fluid bg_apps_usecaes  display-none">
              <div className="row">
                <div className="container">
                  <div className="sticky_menu">
                    <span>AI Ticketing :</span>
                    <ul>
                      {headerMenu.map((menu) => (
                        <li>
                          <AnchorLink
                            offset={() => 160}
                            href={menu.href}
                            className={
                              isActive === menu.menu
                                ? "url_manipulation active"
                                : "url_manipulation "
                            }
                            onClick={() => {
                              setIsActive(menu.menu);
                            }}
                          >
                            {" "}
                            {menu.menu}
                          </AnchorLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <RegisterSection
              backgroundColor={"bg_apps_usecaes "}
              rightImage={aboveFoldImage}
              altImage={"AI Ticketing Automation"}
              videoToken={"__NO_VIDEO_AVAILABLE__"}
              headerMenu={headerMenu}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  AI Ticketing Automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Reduce contact with the helpdesk for repetitive issues and
                  transform the employee experience with AI.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            <div className="mob_sticky_wrapper">
              <div className="dropdown wv-dropdown display-none">
                <h4>AI Ticketing :</h4>
                <button
                  className={`link ${isOpen ? "active" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  {dropdown}
                </button>
                {headerMenu.map((menu) => (
                  <div
                    onClick={() => setOpen(!isOpen)}
                    id="myDropdown"
                    className={`accordion-item submenu dropdown-content ${
                      !isOpen ? "collapsed" : ""
                    }`}
                  >
                    {" "}
                    <li className={menu.id}>
                      <AnchorLink
                        offset={460}
                        className="url_manipulation"
                        href={menu.href}
                        onClick={() => setDropdown(menu.menu)}
                      >
                        {menu.menu}
                      </AnchorLink>
                    </li>
                  </div>
                ))}
              </div>
              <AppsIntegrations
                header={"AI Ticketing made easy with your favorite apps"}
                cards={appsName}
                id={"section_1"}
              />
              <section className="boarding_wrapper">
                <div className="container">
                  <div className="row">
                    {sectionImg.map((card, index) => {
                      if (index % 2 == 0) {
                        return (
                          <div className="boarding_wrapper_container">
                            <div className="row">
                              <div className="col-md-12 col-lg-3 col-12">
                                <picture>
                                  <source
                                    media="(max-width:768px)"
                                    srcSet={card.mobImage}
                                  />
                                  <img
                                    loading="lazy"
                                    src={card.img}
                                    alt={card.alt}
                                  ></img>
                                </picture>
                              </div>
                              <div className="col-md-12 col-lg-9 col-12 pad_left_30">
                                <h4>{card.header}</h4>
                                <p>{card.paragraph} </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div className="boarding_wrapper_container">
                          <div className="row">
                            <div className="col-md-12 col-lg-9 col-12 pad_right_30">
                              <h4>{card.header}</h4>
                              <p>{card.paragraph} </p>
                            </div>
                            <div className="col-md-12 col-lg-3 col-12">
                              <picture>
                                <source
                                  media="(max-width:768px)"
                                  srcSet={card.mobImage}
                                />
                                <img
                                  loading="lazy"
                                  src={card.img}
                                  alt={card.alt}
                                ></img>
                              </picture>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <Cards
                id={"section_2"}
                cards={cardData}
                header={"Why digital AI Ticketing"}
              />
              <section className="automation_steps" id={"section_3"}>
                <div className="container">
                  <h2>How AI Ticketing automation works</h2>

                  <picture>
                    <source
                      media="(max-width:768px)"
                      srcSet={mob_onboard_steps}
                    />
                    <img
                      loading="lazy"
                      src={automation_steps}
                      alt="How AI Ticketing automation works"
                    />
                  </picture>
                </div>
              </section>
              <section className="slack_teams_wrapper" id={"section_4"}>
                <LeftFormRightImage
                  image={slackteam}
                  altImage={"AI Ticketing Chatbot"}
                >
                  <LeftFormRightImage.Header>
                    <h3>AI Ticketing chatbot for Microsoft Teams and Slack</h3>
                  </LeftFormRightImage.Header>
                  <LeftFormRightImage.SubHeader>
                    <p>
                      Deliver round the clock conversational AI-based support to
                      employees right within your Slack and Teams channels.
                      Workativ helps you create a powerful chatbot for AI
                      ticketing to deliver employee support at scale with AI and
                      automated resolution capabilities. Using AI, you can bring
                      down the cost of support and create a better employee
                      experience.
                    </p>
                  </LeftFormRightImage.SubHeader>
                  <LeftFormRightImage.Link>
                    <div className="card_link_landing">
                      <SlackAndTeams />
                    </div>
                  </LeftFormRightImage.Link>
                </LeftFormRightImage>
              </section>
              <Slider
                title={"Other popular use cases"}
                sliderData={sliderData}
                id={"section_5"}
              />
            </div>
            <div className="popular_workflows_">
              <KeyResources cards={resoucesData} />
            </div>
          </section>
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

export const leave = require("../../assets/images/usecases/common/leave_management.png");
export const offboarding = require("../../assets/images/usecases/common/offboarding.png");
export const payroll = require("../../assets/images/usecases/common/payroll.png");
export const onboardinng = require("../../assets/images/usecases/common/onboardinng.png");
export const reset = require("../../assets/images/usecases/common/reset.png");
export const unlock = require("../../assets/images/usecases/common/unlock.png");
export const user_provision = require("../../assets/images/usecases/common/user_provision.png");

const sliderData = [
  {
    header: "Employee Offboarding",
    alt: "Employee Offboarding",
    image: offboarding,
    content:
      "Automate employee offboarding and remove the risk of ex-employees’ access to your systems and network ",
    link: "Learn more",
    url: "/use-cases/employee-offboarding-automation",
  },
  {
    header: "Unlock Account",
    alt: "Unlock Account Automation",
    image: unlock,
    content:
      "Strengthen enduser security and minimize calls to the helpdesk with ‘unlock account’ automation.",
    link: "Learn more",
    url: "/use-cases/unlock-account-automation",
  },
  {
    header: "Employee Onboarding",
    alt: "Employee Onboarding Automation",
    image: onboardinng,
    content:
      "Simplify the onboarding tasks for modern HR teams and deliver a positive onboarding experience.",
    link: "Learn more",
    url: "/use-cases/employee-onboarding-automation",
  },

  {
    header: "Payroll Enquiry",
    alt: "Payroll Enquiry Automation",
    image: payroll,
    content:
      "Streamline automation for delivering payroll information to employees with zero waiting time.",
    link: "Learn more",
    url: "/use-cases/payroll-enquiry-automation",
  },
  {
    header: "Password Reset",
    alt: "Password Reset Automation",
    image: reset,
    content:
      "Expedite issue resolution for employees and reduce service desk call volume with ‘password reset’ automation.",
    link: "Learn more",
    url: "/use-cases/reset-password-automation",
  },
  {
    header: "User Provisioning",
    alt: "User Provisioning Automation",
    image: user_provision,
    content:
      "Reduce costs and improve employee experience with user provisioning automation.",
    link: "Learn more",
    url: "/use-cases/user-provisioning-automation",
  },
  {
    header: "Leave management",
    alt: "Leave management automation",
    image: leave,
    content:
      "Simplify PTO inquiries and requests for employees with an automated, intelligent and user-friendly chatbot.",
    link: "Learn more",
    url: "/use-cases/leave-management-automation",
  },
];

const zure = require("../../assets/images/icons-web/zure.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const twilio = require("../../assets/images/icons-web/twilio.svg");
const ms_office = require("../../assets/images/icons-web/ms_office.svg");
const freshdesk = require("../../assets/images/icons-web/fd_1.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const zohodesk = require("../../assets/images/icons-web/zohodesk.svg");

const appsName = [
  {
    image: zure,
    alt: "Azure",
  },
  {
    image: okta,
    alt: "okta",
  },
  {
    image: jumpcloud,
    alt: "jumpcloud",
  },
  {
    image: ms_office,
    alt: "ms_office",
  },
  {
    image: twilio,
    alt: "twilio",
  },
  {
    image: freshservice,
    alt: "freshservice",
  },
  {
    image: freshdesk,
    alt: "freshdesk",
  },
  {
    image: zohodesk,
    alt: "zohodesk",
  },
];

export const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        loading="lazy"
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        loading="lazy"
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
